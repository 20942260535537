<script setup lang="ts">
import { ref, watch, computed } from "vue";
import * as pressable from "@zag-js/pressable";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { KippieQuantitySelector, SvgIcon } from "../";
import { watchIgnorable } from "@vueuse/core";

const emit = defineEmits(["update", "remove"]);
interface Props {
	title: string;
	price: string;
	placeholder?: string;
	quantity?: number;
	stackable?: boolean;
	removable?: boolean;
}

const {
	placeholder = undefined,
	quantity = undefined,
	stackable = false,
	removable = false,
	title,
	price
} = defineProps<Props>();

const [state, send] = useMachine(
	pressable.machine({
		id: (Math.random() + 1).toString(36).substring(7),
		onPress: () => emit("remove")
	})
);

const api = computed(() => pressable.connect(state.value, send, normalizeProps));

const amount = ref<number>(quantity || 0);

watch(
	() => quantity,
	(v) => ignoreUpdates(() => (amount.value = v || 0))
);

const { ignoreUpdates } = watchIgnorable(
	() => amount.value,
	(v: number) => emit("update", v)
);
</script>

<template>
	<div class="bg-white flex gap-6">
		<div class="bg-yellow rounded-lg aspect-square p-3 flex items-center justify-center w-20 shrink-0 self-start">
			<slot name="image">
				<img v-if="placeholder" :src="placeholder" :alt="title" class="w-full h-full object-contain" />
			</slot>
		</div>
		<div class="flex flex-col grow">
			<div class="flex justify-between items-start gap-4">
				<h4 class="text-base leading-7 font-bold">
					<span v-if="quantity && !stackable">{{ quantity }}X</span>
					{{ title }}
				</h4>
				<h4 class="text-base leading-7 font-bold">
					{{ price }}
				</h4>
			</div>
			<div class="flex flex-col text-xs text-gray">
				<slot />
			</div>
			<div v-if="stackable || removable" class="pt-4 flex justify-between items-center">
				<span>
					<KippieQuantitySelector
						v-if="quantity && stackable"
						v-model="amount"
						:name="`Quantity selector ${title}`"
					/>
				</span>
				<button v-if="removable" v-bind="api.pressableProps">
					<SvgIcon name="trash" class="text-gray hover:text-black-light" />
				</button>
			</div>
		</div>
	</div>
</template>
